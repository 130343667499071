<template>
  <userTemplate :pattern="2">
    <div class="bg-primary text-white pd-x-5">
      <el-row>
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 16, offset: 4 }"
          :lg="{ span: 8, offset: 8 }"
        >
          <div class="float-left">
            <p>ชื่อห้อง: {{ room.name }}</p>
          </div>
          <div class="float-right">
            <p>Room ID: {{ room.code }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row class="mg-t-1 pd-x-5">
      <el-col
        :xs="{ span: 24, offset: 0 }"
        :sm="{ span: 16, offset: 4 }"
        :lg="{ span: 8, offset: 8 }"
      >
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-position="'top'"
        >
          <el-form-item label="กรุณากรอกชื่อเพื่อเข้าร่วมทดสอบ" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          class="w-100 mg-t-3"
          @click="joinRoom()"
          :loading="btnLoading"
          >เข้าร่วม</el-button
        >
      </el-col>
    </el-row>
  </userTemplate>
</template>
<script>
import { HTTP } from "@/service/axios";
import userTemplate from "@/template/VoteUserTemplate";
export default {
  components: {
    userTemplate
  },
  data() {
    return {
      click: 0,
      rules: {
        name: [{ required: true, message: "กรุณากรอกชื่อ", trigger: "blur" }]
      },
      form: {
        name: ""
      },
      btnLoading: false,
      stateNow: null
    };
  },
  mounted() {
    this.getDataRoom(this.$route.params.id, false);
  },
  methods: {
    async getStateNow() {
      let obj = this.room.roomId;
      await HTTP.get(`state/${obj}`).then(async res => {
        // console.log(`state/${obj}`, res);
        if (res.data.success) {
          let stateObj = {
            attId: res.data.obj.attId,
            id: res.data.obj.id,
            qId:
              res.data.obj.qId != "undefined"
                ? res.data.obj.qId
                : res.data.obj.qid,
            roomId: res.data.obj.roomId,
            state: res.data.obj.state,
            total: res.data.obj.total
          };
          // console.log("stateObj", stateObj);
          await this.$store.commit("SET_STEP", stateObj);
          this.stateNow = res.data.obj;
        }
      });
    },
    checkJoinRoom() {
      let userData = this.room.players.filter(
        a => this.user != null && a.id == this.user.id
      );
      // console.log("user", userData);
      if (userData.length > 0) {
        if (this.stateNow == null) {
          this.$router.push("/waiting-room");
        } else {
          this.checkRouterUser(this.stateNow.state);
          return true;
        }
      } else {
        return false;
      }
    },
    getDataRoom(code, next = true) {
      HTTP.get(`room/${code}`)
        .then(async result => {
          // console.log(`room/${code}`, result);
          if (result.data.success) {
            this.$store.commit("SET_ROOM", result.data.obj);
            await this.getStateNow();
            if (
              result.data.obj.code == this.$route.params.id &&
              result.data.obj.status == 1
            ) {
              this.checkJoinRoom();
            }
          } else {
            this.$notify.error({
              title: "ขออภัย",
              message: "เลขห้องไม่ถูกต้อง"
            });
            this.$router.push("/");
          }
        })
        .catch(err => {});
    },
    async serviceJoin() {
      let obj = {
        roomId: this.room.roomId,
        name: this.form.name
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      let res = await HTTP.post("join", obj);
      return res;
    },
    async serviceGetRoomById() {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      let res = await HTTP.get(`room/${this.$route.params.id}`);
      return res;
    },
    async joinRoom() {
      this.btnLoading = true;
      this.click = this.click + 1;
      if (!this.checkJoinRoom()) {
        this.$refs.ruleForm.validate(async valid => {
          if (valid && this.click == 1) {
            let result = await this.serviceJoin();
            if (result.data.success) {
              if (result.data.data == "Join room success.") {
                let room = await this.serviceGetRoomById();
                if (room.data.success) {
                  this.$store.commit("SET_ROOM", room.data.obj);
                  let getUser = this.room.players.filter(
                    a => a.name == this.form.name
                  );
                  this.$store.commit("SET_USER", getUser[0]);
                  if (this.stateNow == null) {
                    this.$router.push("/waiting-room");
                  } else {
                    this.checkRouterUser(this.stateNow.state);
                  }
                }
              } else {
                this.btnLoading = false;
                this.$message({
                  message: result.data.data,
                  type: "warning"
                });
              }
            } else {
              this.btnLoading = false;
            }
          } else {
            this.click = 0;
            this.btnLoading = false;
            this.$notify.error({
              title: "ขออภัย",
              message: "ไม่สามารถเข้าร่วมห้องนี้ได้"
            });
          }
        });
      } else {
        this.btnLoading = false;
      }
    }
  }
};
</script>